var CatalogFilter = function () {
    this.init();
};

CatalogFilter.prototype = (function () {
    var init,
        loadTranslate,
        sendStatistics,
        craeteResetButton,
        setLocation,
        apply,
        reload,
        reset,
        _this,
        getParamString,
        makeUrl,
        onChange,
        setEventListiners,
        wrapper;

    var ACTIVE_CLASS = 'filter_active';

    sendStatistics = function (action, params) {
        if (yaCounter30530712) {
            yaCounter30530712.reachGoal(action, params);
        }
    };

    reset = function () {
        var href = location.href;
        var exp = /(\?|&)filter=(.*?)(&|$)/g;
        href = href.replace(exp, "");
        location = href;
    };

    apply = function () {
        var paramString = getParamString();
        var url = makeUrl(paramString);

        setLocation(url);
    };

    reload = function () {
        var paramString = getParamString();
        var url = makeUrl(paramString);

        $.get(url)
            .then(function (response) {
                wrapper.html(response);

                craeteResetButton();
                setEventListiners();
            });
    };

    makeUrl = function (filter) {
        return window.location.protocol + '//' + window.location.hostname + window.location.pathname + '?filter=' + filter;
    };

    craeteResetButton = function () {
        var href = window.location.href;
        if (/(\?|&)filter=(.*?)/.test(href)) {
            $("#filter_apply_button").after('<br><br>[ <a id="reset-filter">' + _this.translate.text_reset_filter + '</a> ]');
        }
    };

    getParamString = function () {
        var string = '';
        var params = {};

        $('.' + ACTIVE_CLASS).each(function () {
            var key = $(this).data('key');
            var value = $(this).data('value');

            if (params[key] === undefined) {
                params[key] = '';
                params[key] += value;
            }
            else {
                params[key] += ',' + value;
            }
        });

        $.each(params, function (index, value) {
            string += index + ':' + value + ';';
        });

        return string.substr(0, string.length - 1);
    };

    setLocation = function (location) {
        window.location = location;
    };

    onChange = function () {
        reload();
    };

    setEventListiners = function () {
        $('#reset-filter').on('click', reset);
        $('#catalog-filter').on('change', onChange);

        $("#filter_apply_button").on('click', function (event) {
            apply();
            sendStatistics('apply_filter');

            event.preventDefault();
        });

        $(".filter-item a").on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass(ACTIVE_CLASS);
            var checkbox = $(this).siblings("input:checkbox");
            if (checkbox.is(':checked')) {
                checkbox.attr('checked', false);
            }
            else {
                checkbox.attr('checked', true);
            }
        });

        $('.filter-item-select select').on('change', function (event) {
            $('option', this).removeClass(ACTIVE_CLASS);
            $('option:selected', this).addClass(ACTIVE_CLASS);
        });

        $('.filter-item-checkbox input:checkbox, .filter-item-select input:checkbox').on('click', function () {
            $(this).siblings("a").toggleClass(ACTIVE_CLASS);
            $(this).parents(".filter-item-select-list").show();
        });
    };

    init = function () {
        _this = this;
        wrapper = $('#catalog-filter-wrapper');
        this.translate = loadTranslate();

        craeteResetButton();
        setEventListiners();

        $(document).on('click', function (e) {
            var $target = $(e.target);
            if (!$target.is("a") && !$target.is("input:checkbox")) {
                $(".filter-item-select-list").hide();
            }
        });
    };

    loadTranslate = function () {
        var json = $('#catalog-filter-translate').text().trim(),
            result = {};

        try {
            if (json) {
                result = JSON.parse(json);
            }
        }
        catch (e) {}

        return result;
    };

    return {
        init: init
    };
} ());